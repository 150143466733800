.taxi-1 {
    background-image: url(/src/assets/projects/taxi/taxi-1.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 700px;
    height: 700px;
    margin: 50px auto;
}

.client-taxi {
    background-image: url(/src/assets/projects/taxi/logo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 300px;
    height: 150px;
}

.taxi-2 {
    background-image: url(/src/assets/projects/taxi/mainpage.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 1200px;
    height: 700px;
    margin: 50px auto;
}

.taxi-3 {
    background-image: url(/src/assets/projects/taxi/branding.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 1200px;
    height: 700px;
    margin: 50px auto;
}

.taxi-4 {
    background-image: url(/src/assets/projects/taxi/typo.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 1200px;
    height: 660px;
    margin: 50px auto;
}

.taxi-5 {
    background-image: url(/src/assets/projects/taxi/colors.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 1200px;
    height: 300px;
    margin: 50px auto;
}



@media (max-width: 1300px) {
    .client-taxi {
        margin-right: 50px;
    }
    .taxi-2 {
        width: 800px;
    }

    .taxi-3 {
        width: 800px;
        height: 800px;
        
    }
    .taxi-4 {
        width: 800px;
    }
    .taxi-5 {
        width: 800px;
    }
}

@media (max-width: 910px) {
    .taxi-1{
        width: 600px;
        height: 400px;
    }
    .taxi-2 {
        width: 600px;
        height: 400px;
        
    }
    .taxi-3 {
        width: 600px;
        height: 600px;
        
    }
    .taxi-4 {
        width: 600px;
        height: 400px;
        }
    .taxi-5 {
        width: 600px;
        
    }
  
}

@media (max-width: 700px) {
    .taxi-1 {
        width: 400px;
        height: 300px;
    }
    .taxi-2 {
        width: 400px;
        height: 300px;
        
    }
    .taxi-3 {
        width: 400px;
        height: 400px;
        
    }
    .taxi-4 {
        width: 400px;
        height: 300px;
        }
    .taxi-5 {
        width: 400px;
        height: 200px;
        
    }
    
}

@media (max-width: 500px) {
    .taxi-1 {
        width: 300px;
        height: 200px;
    }

    .client-taxi {
        width: 300px;
        height: 100px;
        margin-right: 0;
    }
    .taxi-2 {
        width: 200px;
        height: 100px;
        
    }
    .taxi-3 {
        width: 200px;
        height: 200px;
        
    }
    .taxi-4 {
        width: 200px;
        height: 100px;
        }
    .taxi-5 {
        width: 200px;
        height: 100px;
        
    }
    
}