.contact {
    margin-top: 50px;
}

.contact-page {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    /* margin-top: 200px; */
}

.contact-title {
    font-family: 'monument';
    font-size: 50px;
    text-align: center;
}

.hover-contact:hover {
    color: #357a5e;
    cursor: default;
}

.contact-card {
    margin-top: 50px;
    margin-bottom: 200px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid black;
    padding: 5px 20px;
    width: 900px;
}

.contact-mailto {
    text-decoration: none;
    color: #357a5e;
    font-family: 'monument';
    font-size: 30px;
    text-align: left;
}

.contact-icon {
    color: #357a5e;
    font-size: 25px;
}

@media(max-width: 920px) {
    .contact-title {
        font-size: 45px;
    }
    .contact-card {
        width: 760px;
    }
}

@media(max-width: 768px) {
    .contact-card {
        width: 700px;
    }
}

@media(max-width: 720px) {
    .contact-title {
        font-size: 40px;
    }
    .contact-card {
        width: 530px;
    }
}

@media(max-width: 550px) {
    .contact-title {
        font-size: 35px;
    }

    .contact-card {
        width: 300px;
        padding: 0;
        align-items: center;
    }
}