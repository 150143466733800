.footer {
    border-top: 3px solid black;
    align-content: center;
}

.footer_nav{
    display: flex;
    justify-content: space-between;
    padding: 10px 50px;
    align-items: center;
    font-family: 'monument';
    font-size: 15px;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
}

.footer_contact{
    border: 1px solid black;
    border-radius: 20px;
    padding: 2px 20px;
    color: black;
    text-decoration: none;
}

.footer_contact:hover{
    background-color: #357a5e;
    border: 1px solid #357a5e;
    color: #fcf3ea;
    transition: .3s ease;
}

.footer_link{
    display: flex;
    justify-content: space-between;
    width: 500px;
}

.social_link{
    color: #357a5e;
    text-decoration: none;
}

.social_link:hover{
    color: black;
}

.footer_rights{
    margin-top: 50px;
    font-family: 'druk';
    font-size: 45px;
    text-align: center;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
}

@media(max-width: 900px){
    .footer_nav{
        flex-direction: column;
        gap: 30px;
        align-items: center;
    }
}

@media(max-width: 550px){
    .footer_link{
        flex-direction: column;
        gap: 13px;
        align-items: center;
        width: 300px;
    }
    .footer_rights{
        font-size: 20px;
    }
}