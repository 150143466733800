.project {
    max-width: 1000px;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid red; */
}

.project-link {
    cursor: pointer;
    color: black;
}

.title-project {
    font-size: 50px;
    font-family: var(--akira);
    margin-top: 100px;
}

.detail-text-project {
    font-size: 13px;
    margin-bottom: 100px;
    font-family: var(--helvmed);
    color: var(--black);
    line-height: 20px;
}

.project-container-1 {
    /* border: 1px solid black; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    border: .5px solid none;
    border-radius: 25px;
    padding: 20px 50px;
}

.project-container-2 {
    /* border: 1px solid black; */
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 20px 0;

    border: .5px solid none;
    border-radius: 25px;
    padding: 20px 50px;
}

.project-container-1:hover {
    background-color: #f5f5f5;
    
}

.project-container-2:hover {
    background-color: #f5f5f5;
}

.project-container-detail {
    /* border: 1px solid blue; */
    display: flex;
    flex-direction: column;

}

.project-title {
    font-size: 40px;
    font-family: var(--akira);
    color: var(--primarycolor);
}

.project-title:hover {
    color: black;
    transition: ease-in .2s;
}

.project-brand {
    font-family: var(--helvmed);
    font-size: 13px;
    color: #656565;
}

.project-brand:hover {
    color: var(--primarycolor);
}

.project-category {
    display: flex;
    justify-content: start;
    margin-top: 15px;
    gap: 10px;
}

.category {
    border: 1px solid black;
    background-color: white;
    border-radius: 20px;
    font-size: 10px;
    font-family: var(--helvmed);
    padding: 0 5px;

}




/* img */

.img-porsche {
    background-image: url(/src/assets/projects/porsche.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 500px;
    height: 280px;

}

.img-taxi {
    background-image: url(/src/assets/projects/taxi.png);
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    width: 500px;
    height: 280px;
}




/* button */

.project-btn {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    margin-bottom: 100px;
}

.work-btn {
    border: 1px solid black;
    background-color: transparent;
    border-radius: 20px;
    padding: 10px 20px;
    font-size: 15px;
}

.work-btn:hover {
    cursor: pointer;
    transition: ease-in .3s;
    border: 1px solid var(--primarycolor);
    color: white;
    background-color: var(--primarycolor);
}

/*  responsive */

@media (max-width: 1024px) {
    .title-project {
        margin-left: 50px;
        margin-right: 50px;
    }

    .detail-text-project {
        margin-left: 50px;
        margin-right: 50px;
    }
    .project-container-1 {
        margin-left: 20px;
        margin-right: 20px
    }

    .project-container-2 {
        margin-left: 20px;
        margin-right: 20px
    }
}

@media (max-width: 800px) {
    .category {
        font-size: 8px;
    }
}

@media (max-width: 700px) {
    .project-title {
        font-size: 30px;
        text-align: center;
    }

    .project-brand {
        text-align: center;
    }

    .project-container-1{
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;
        gap: 20px;
        
    }

    .project-container-2 {
        flex-direction: column;
        gap: 20px;
    }

    .project-category {
        justify-content: center;
    }
}

@media (max-width: 500px) {
    .title-project {
        font-size: 35px;
        margin-left: 20px;
        margin-right: 20px;
    }

    .detail-text-project {
        margin-left: 20px;
        margin-right: 20px;
    }
    .img-porsche {
        width: 300px;
    }

    .img-taxi {
        width: 300px;
    }
}

@media (max-width: 360px){
    .project-container-detail {
        align-items: center;
    }

    .project-category {
        flex-direction: column;
        align-items: center;
    }
}