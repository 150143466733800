/* title */

.about-title {
    margin-top: 50px;

    font-size: 100px;
    text-align: right;
    color: var(--primarycolor);
    font-family: var(--akira);
    letter-spacing: -4px;
}

.about-title-little {
    font-size: 50px;
    font-style: italic;
}

/* container img */

.about-container-img {
    display: flex;
    justify-content: space-between;

    /* border: 1px solid red; */
    margin-bottom: 50px;
}

.about-container-text {
    display: flex;
    flex-direction: column;
    gap: 100px;

    /* border: 1px solid cyan; */
}

.about-container-location {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.location-title {
    color: var(--primarycolor);
    font-size: 18px;
    font-family: var(--akira);
}

.location-text {
    font-family: var(--helvmed);
    font-size: 22px;
}

.about-container-social {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.social-title {
    color: var(--primarycolor);
    font-size: 18px;
    font-family: var(--akira);
}

.social-icon {
    font-size: 22px;
    color: black;
}

.about-img {
    background-image: url(/src/assets/about/me.png);
    background-position: top;
    background-size: contain;
    background-repeat: no-repeat;
    width: 300px;
    height: 450px;
}

/* container intro */

.about-text-intro {
    font-family: var(--akira);
    font-style: italic;
    font-size: 40px;
    text-align: right;
    margin-bottom: 20px;
}

.about-text-intro-little {
    font-size: 17px;
    font-family: var(--helvmed);
    color: var(--black);
}

.sm-show1{
    display: none;
}

/* responsive */

@media (max-width: 1300px) {
    .about {
        margin-left: 50px;
        margin-right: 50px;
    }
}

@media (max-width: 1200px) {
    .about-title {
        font-size: 80px;
    }
}

@media (max-width: 700px) {
    .about-title {
        font-size: 50px;
    }

    .about-text-intro {
        font-size: 25px;
    }
}

@media (max-width: 650px) {
    .about-container-img {
        flex-direction: column-reverse;
        gap: 25px;
        align-items: center;
    }

    .about-container-text {
        gap: 30px;
    }
}

@media (max-width: 480px) {
    .about-title {
        font-size: 40px;
    }

    .about-text-intro {
        font-size: 20px;
    }

    .about-text-intro-little {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .sm-hidden3 {
        display: none;
    }

    .sm-show1{
        display: contents;
    }
}

@media (max-width: 375px) {
    .about-title {
        font-size: 30px;
    }

    .about-text-intro {
        font-size: 18px;
    }
}